body {
    background-color: #d6dde2;
    color: #144663;
}

h2 {
    margin-bottom: 2rem;
}

h3 {
    color: #009bde;
    text-align: center;
}

h5 {
    margin: 0px;
}

.bg-dark {
    background-color: #006098 !important;
}

.bg-dark-top {
    background-color: #b22028 !important;
}

.bg-dark-left {
    background-color: #ffa400;
}

.jumbotron {
    background-color: #fff;
    padding: 2rem 0 4rem;
}

.form-group {
    margin-bottom: 0.7rem;
    margin-top: 0.7rem;
}

.btn-primary {
    border-color: #006098;
    background-color: #006098;
    /*color : white !important;*/
}

.list-group-item {
    background-color: #545b62 !important;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
}
.list-group-item-demo {
    background-color: #006098 !important;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
}

.list-group-item:hover {
    background-color: #0069d9 !important;
}


.calculation-list {
    background-color: #b22028 !important;
}

.card-header {
    padding: 20px !important;
   
    
}

.calculation-list:hover {
    background-color: #006098 !important;
    color: #fff;
}

.calculation-list:focus{
    color: #fff;
}


.card-results {
    font-weight: bold;
    background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) );
}
/*NAV-BAR*/
.navbar {
    margin-bottom: 20px;
    padding: 0 1rem;
}


.navbar-toggler {
    margin: .5rem 0;
}


html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.text-dark {
    color: #ffffff !important;
}

/*REMOVE UNDERLINE FROM LINKS/BUTTONS*/
a:hover {
    text-decoration: none;
}

/*FORMULA DEFINITION*/
.formula-definition {
    background-color: #7d868c;
    border: none !important;
}

.answer-card-red {
    background-color: #ff0000;
    height: 100%;
    border-radius: 0.25rem;
}

.answer-card-grey {
    background-color: #7d868c;
    height: 100%;
    border-radius: 0.25rem;
}

.answer-card-green {
    background-color: green;
    height: 100%;
    border-radius: 0.25rem;
}

/*.card-header {
    font-weight: bold;
    background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) );
}*/
.img-fluid {
    cursor: pointer;
    margin:25px 0px 45px 0px;
}

.register-list {
    background-color: #b22028 !important;
    margin-bottom:40px;
}
.register-list:hover {
        background-color: #006098 !important;
        color: #fff;
 }

register-list:focus {
        color: #fff;
 }
.def {
    color: #144663 !important;
    font-weight: bold;
    text-align: justify;

}
.pdf-button {
    background-color: #545b62 !important;
    color: #fff;
    width: 100%;
    margin:10px;
}
    .pdf-button:hover {
        color: #fff;
    }
.pdf-button:focus {
    color: #fff;
}
.test {
    padding-left: 0px;
    margin: 10px 0px 0px -5px;
}
@media screen and (max-width: 960px) {
    .right {
        margin: 10px 0px 0px -5px;
    }
}
.btn-group {
    padding: 46px 7px 7px 11px;
}